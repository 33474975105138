body {
  margin: 0;
}

jw-word-app {
  .p-tabmenu {
    .p-tabmenu-nav {
      padding: 0 0.25rem !important;
      min-width: fit-content;
      border: none;
      background-color: var(--surface-900);
      li {
        &.p-highlight {
          border-bottom: 3px solid var(--primary-color);
          .p-menuitem-text {
            color: white !important;
          }
          .p-menuitem-icon {
            color: var(--primary-color) !important;
          }
        }
      }
      .p-menuitem-link {
        font-weight: 700;
        border: none !important;
        background: none !important;
        padding: 1.25rem 0.5rem !important;
        &:not(.p-disabled):focus {
          box-shadow: none !important;
        }
      }
    }
    .p-tabmenu-nav-next,
    .p-tabmenu-nav-prev {
      background-color: var(--surface-900);
      .p-element {
        color: var(--surface-100);
      }
    }
  }

  .p-tabview {
    .p-tabview-nav {
      background-color: var(--surface-900);
      padding: 0;
      li {
        &.p-highlight {
          border-bottom: 3px solid var(--primary-color);
        }
      }
      .p-tabview-nav-link {
        border: none !important;
        background: none !important;
        padding: 0 !important;
        &:not(.p-disabled):focus {
          box-shadow: none !important;
        }
      }
    }
    .p-tabview-nav-next,
    .p-tabview-nav-prev {
      background-color: var(--surface-900);
      height: calc(100% - 2px);
      .p-element {
        color: var(--surface-100);
      }
    }
    .p-tabview-panels {
      background-color: var(--surface-100);
    }
  }

  // Dropdown
  .p-dropdown {
    &:not(.p-disabled).p-focus {
      box-shadow: none;
      border-color: var(--primary-color);
    }

    &:not(.p-disabled):hover {
      box-shadow: none;
      border-color: var(--primary-color);
    }
  }

  // Input text
  .p-inputtext {
    box-shadow: none !important;
    &:enabled:hover {
      border-color: var(--primary-color);
    }
    &:enabled:focus {
      border-color: var(--primary-color);
    }
  }

  // Button
  .p-button {
    background-color: var(--primary-color);
    border: none;
    font-weight: 700;
    box-shadow: none;
    &.p-button-secondary {
      background-color: var(--background-color-secondary);
      &.p-button-outlined {
        color: var(--background-color-secondary);
        border: 1px solid var(--background-color-secondary);
      }
    }
    &:not(.p-disabled):hover {
      background-color: var(--primary-color);
    }
    &:not(.p-disabled):focus {
      box-shadow: none !important;
    }
  }

  .p-button.p-button-outlined {
    border: 1px solid;
    background-color: transparent;
    color: var(--primary-color);
  }

  .p-button.p-button-outlined:enabled:hover {
    background: unset;
  }

  // Label
  .label {
    color: var(--text-color-secondary);
    font-size: 14px;
    margin-bottom: 0.75rem;
  }

  // Spinner
  p-progressspinner {
    position: fixed;
    top: 47%;
    left: 40%;
  }

  // Card
  .p-card .p-card-body {
    padding: 0 1.25rem;
  }

  // Slider
  .p-slider {
    .p-slider-range {
      background-color: var(--primary-color);
    }
    .p-slider-handle {
      border-color: var(--primary-color);
      &:focus {
        box-shadow: 0 0 0 0.2rem var(--orange-200);
      }
    }
    &:not(.p-disabled) .p-slider-handle:hover {
      background: var(--primary-color);
      border-color: var(--primary-color);
    }
  }

  .p-radiobutton .p-radiobutton-box:not(.p-disabled, .p-highlight):hover {
    border-color: var(--primary-color);
  }

  .p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
    border-color: var(--primary-color);
    box-shadow: 0 0 0 0.2rem var(--primary-focus-color);
    outline: 0 none;
    outline-offset: 0;
  }

  .p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: var(--primary-color);
    background: var(--primary-color);
  }

  .p-checkbox {
    width: 20px;
    height: 20px;
  }

  .p-checkbox .p-checkbox-box {
    width: 20px;
    height: 20px;
    border: 2px solid #ced4da;
    border-radius: 3px;
    background: #fff;
    color: #495057;
    transition:
      background-color 0.2s,
      color 0.2s,
      border-color 0.2s,
      box-shadow 0.2s;
  }

  .p-checkbox .p-checkbox-box .p-checkbox-icon {
    color: #fff;
    font-size: 14px;
    transition-duration: 0.2s;
  }

  .p-checkbox .p-checkbox-box.p-highlight {
    border-color: var(--primary-color);
    background: var(--primary-color);
  }

  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: var(--primary-color);
  }

  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    border-color: var(--primary-color);
    box-shadow: 0 0 0 0.2rem var(--primary-focus-color);
    outline: 0 none;
    outline-offset: 0;
  }

  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    border-color: var(--primary-dark-color);
    background: var(--primary-dark-color);
    color: #fff;
  }

  p-checkbox.ng-dirty.ng-invalid > .p-checkbox > .p-checkbox-box {
    border-color: #f44336;
  }

  .p-input-filled .p-checkbox .p-checkbox-box {
    background-color: #f8f9fa;
  }

  .p-input-filled .p-checkbox .p-checkbox-box.p-highlight {
    background: var(--primary-color);
  }

  .p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    background-color: #f8f9fa;
  }

  .p-input-filled
    .p-checkbox:not(.p-checkbox-disabled)
    .p-checkbox-box.p-highlight:hover {
    background: var(--primary-dark-color);
  }

  .p-checkbox-label {
    margin-left: 0.5rem;
  }

  .p-selectbutton .p-button {
    border: 1px solid #ced4da;
    background: #fff;
    color: #495057;
    transition:
      background-color 0.2s,
      color 0.2s,
      border-color 0.2s,
      box-shadow 0.2s;
  }

  .p-selectbutton .p-button .p-button-icon-left,
  .p-selectbutton .p-button .p-button-icon-right {
    color: #6c757d;
  }

  .p-selectbutton .p-button:not(.p-disabled, .p-highlight):hover {
    border-color: #ced4da;
    background: #e9ecef;
    color: #495057;
  }

  .p-selectbutton
    .p-button:not(.p-disabled, .p-highlight):hover
    .p-button-icon-left,
  .p-selectbutton
    .p-button:not(.p-disabled, .p-highlight):hover
    .p-button-icon-right {
    color: #6c757d;
  }

  .p-selectbutton .p-button.p-highlight {
    border-color: var(--primary-color);
    background: var(--primary-color);
    color: #fff;
  }

  .p-selectbutton .p-button.p-highlight .p-button-icon-left,
  .p-selectbutton .p-button.p-highlight .p-button-icon-right {
    color: #fff;
  }

  .p-selectbutton .p-button.p-highlight:hover {
    border-color: var(--primary-dark-color);
    background: var(--primary-dark-color);
    color: #fff;
  }

  .p-selectbutton .p-button.p-highlight:hover .p-button-icon-left,
  .p-selectbutton .p-button.p-highlight:hover .p-button-icon-right {
    color: #fff;
  }

  p-selectbutton.ng-dirty.ng-invalid > .p-selectbutton > .p-button {
    border-color: #f44336;
  }
}
